<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Detail Target</strong>
        </h5>
        <a-spin v-if="isLoading" />
        <a-form :form="form"  @submit="handleSubmit" v-if="!isLoading">
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Tanggal">
            <a-month-picker style="width: 100%;" :disabled="submitting" placeholder="Select Date" v-decorator="['date', {rules: [{ required: true, message: 'Tanggal harus diisi!' }]}]" />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Total">
            <a-input-number
              style="width: 100%;"
              :min="0"
              :disabled="submitting"
              placeholder="Masukkan Total target"
              v-decorator="['total', {rules: [{ required: true, message: 'Total harus diisi!' }]}]"
            />
          </a-form-item>
          <button type="submit" class="btn btn-success px-5" :disabled="submitting">Simpan Data</button>
          <button class="btn btn-danger px-5 m-1" :disabled="submitting" @click="(e)=>{e.preventDefault(); setModalVisible(true)}">Hapus Data</button>
          <button class="btn btn-secondary px-5" :disabled="submitting" @click="handleCancel">Kembali</button>
        </a-form>
      </div>
    </div>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible"
      @ok="() => handleDelete()"
      @cancel="() => setModalVisible(false)"
    >
      Apakah Anda yakin akan menonaktifkan data target {{form.getFieldValue("name")}}?
    </a-modal>
  </div>
</template>
<script>

import router from '@/router'
import { getById, editById, deleteById } from '@/services/axios/api/targetpasien'
import { getAll } from '@/services/axios/api/city'
import { notification } from 'ant-design-vue'
const moment = require('moment')

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      isLoading: true,
      submitting: false,
      statusList: [{ id: 1, name: 'Aktif' }, { id: 0, name: 'Tidak Aktif' }],
      modalVisible: false,
    }
  },
  created() {
    this.getTargetById()
    this.getCityList()
  },
  methods: {
    async getTargetById() {
      // init form field
      this.form.getFieldDecorator('date', { initialValue: '' })
      this.form.getFieldDecorator('total', { initialValue: '' })

      // get data
      const res = await getById(this.$route.params.id)

      // set loading false
      this.isLoading = false

      if (res) {
        // set form data
        this.form.setFieldsValue({ date: moment('0' + res.month + '-01-' + res.year).format('MM-DD-YYYY') })
        this.form.setFieldsValue({ total: res.total })
      }
    },
    async getCityList() {
      const res = await getAll()
      this.cityList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await editById(this.$route.params.id, { date: moment(values.date._d).add(5, 'days'), year: moment(values.date._d).format('YYYY'), month: moment(values.date._d).format('MM'), total: values.total })
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil disimpan',
                duration: 5,
              })
              router.push('/target-pasien')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    async handleDelete() {
      const res = await deleteById(this.$route.params.id)
      if (res && res.data) {
        notification.success({
          message: 'Sukses',
          description: 'Data berhasil dihapus',
          duration: 5,
        })
        router.push('/target-pasien')
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Data gagal dihapus',
          duration: 5,
        })
      }
      this.submitting = false
      this.modalVisible = false
    },
    handleCancel(e) {
      e.preventDefault()
      router.push('/target-pasien')
    },
    setModalVisible(modalVisible) {
      this.modalVisible = modalVisible
    },
  },
}
</script>
